import React from "react";
import ChatIcon from "./components/icons/chat-icon.jsx";
import WorkspaceIcon from "./components/icons/workspace-icon.jsx";
import MediaIcon from "./components/icons/media-icon.jsx";
import PlusIcon from "./components/icons/plus-icon.jsx";
// import BackIcon from "./icons/back-icon.jsx";
// import SaveIcon from "./icons/save-icon.jsx";
import ViewImageModal from "./components/modals/ViewImageModal.jsx";
import SkeletonLoader from "./components/SkeletonLoader.jsx";

const Chat = React.lazy(() => import('./screens/Chat/Chat.jsx'));
const Workspace = React.lazy(() => import('./screens/Workspace/Workspace.jsx'));
const Media = React.lazy(() => import('./screens/Media/Media.jsx'));

const TABS = {
  CHAT: 'CHAT',
  WORKSPACE: 'WORKSPACE',
  MEDIA: 'MEDIA'
};

const App = () => {
  const [activeTab, setActiveTab] = React.useState(TABS.CHAT);

  const renderTabContent = () => {
    switch (activeTab) {
      case TABS.CHAT:
        return <Chat />;
      case TABS.WORKSPACE:
        return <Workspace />;
      case TABS.MEDIA:
        return <Media />;
      default:
        return null;
    }
  };

  return (
    <div className="ai-chat overflow-y-auto overflow-x-hidden w-full flex flex-col h-[100vh] p-0 -my-0 lg:p-8 lg:-my-8 gap-1 md:gap-8">
      <div className="self-stretch justify-between items-center inline-flex flex-col gap-2 md:gap-1 md:flex-row">
        <h2 className="title-h2">Personal Assistant</h2>

        <div className="justify-start items-start gap-3 flex">
          <div 
            className={`label ${ activeTab === TABS.CHAT ? 'active fill-purple' : ''}`} 
            onClick={() => setActiveTab(TABS.CHAT)}
          >
            <ChatIcon />
            Ai Chat
          </div>

          <div 
            className={`label ${ activeTab === TABS.WORKSPACE ? 'active fill-purple' : ''}`} 
            onClick={() => setActiveTab(TABS.WORKSPACE)}
          >
            <WorkspaceIcon />
            Workspace
          </div>

           <div 
            className={`label ${ activeTab === TABS.MEDIA ? 'active fill-purple' : ''}`} 
            onClick={() => setActiveTab(TABS.MEDIA)}
          >
            <MediaIcon />
            Media
          </div>
        </div>

        <button className="px-5 py-2.5 rounded-lg justify-center bg-transparent items-center gap-2 flex">
          <div className="text-center text-[#181b2f] text-sm font-semibold leading-tight">Ai Avatar</div>
          <PlusIcon />
        </button>
      </div>

      <div className={`${activeTab !== TABS.CHAT ? '!overflow-y-scroll' : ''} card-main grow shadow flex-col justify-start items-start inline-flex border border-[#e6e6e8]`}> 
        <React.Suspense fallback={<div/>}>
          {renderTabContent()}
        </React.Suspense>
      </div>
      <ViewImageModal />
    </div>
  );
};

// const PageHeader = () => {
//     return (
//         <div className="w-full mb-8     pl-24">
//             <div className="relative w-full flex justify-between">
//                 <a
//                     href="/myself"
//                     className="text-black px-5 py-2.5 text-neutral-text font-semibold flex items-center justify-center gap-x-2"
//                 >
//                     {/*<BackIcon/>*/}
//                     Back
//                 </a>

//                 <button
//                     onClick={() => null}
//                     className="main-button purple px-5 py-2.5 text-neutral-text font-semibold flex justify-center items-center gap-x-2 rounded-lg"
//                 >
//                     {/*<SaveIcon/>*/}
//                     Save
//                 </button>
//             </div>
//         </div>
//     )
// }

export default App;
